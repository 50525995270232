
import VueTypes from 'vue-types'
import { UiButton } from '~/components/atoms'
import { MainTitle, StandardAccordion } from '~/components/molecules'
import { Row, Column, Container } from '~/components/grid'

export default {
  components: {
    MainTitle,
    StandardAccordion,
    Row,
    Column,
    Container,
    UiButton,
  },
  props: {
    title: VueTypes.string.def(''),
    accordionItems: VueTypes.array.def([]),
  },
  methods: {
    handleBackToTop() {
      this.$gtmEnhanced.trackBackToTop()
      this.$scrollTo('header', 1000)
    },
  },
}
